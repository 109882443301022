const webUtils = require('@emobg/web-utils');
const first = require('lodash/first');
const keys = require('lodash/keys');

export default (payload, config) => {
  const payloadToObject = JSON.parse(payload);
  const resource = first(keys(payloadToObject.resource));
  const token = webUtils.signJWT(payloadToObject, config.secretKey);
  return `${config.url}/embed/${resource}/${token}#bordered=true&titled=true`;
};
