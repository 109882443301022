var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._b({}, "GenericModalComponent", _vm.$attrs, false),
    [
      _c(
        "template",
        { slot: "body" },
        [
          _c(
            "MuiValidationWrapper",
            { on: { areAllValid: (valid) => (_vm.isFormValid = valid) } },
            [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-12 mb-2" },
                  [
                    _c("MuiInputText", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: { isRequired: true },
                          expression: "{ isRequired: true }",
                        },
                      ],
                      staticClass: "w-100",
                      attrs: {
                        label: `${_vm.upperFirst(
                          _vm.$t("Common.Descriptions.name")
                        )}*`,
                        placeholder: "Enter a name",
                        name: "name",
                      },
                      model: {
                        value: _vm.inputs.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.inputs, "name", $$v)
                        },
                        expression: "inputs.name",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-md-4 mb-2" },
                  [
                    _vm.ready
                      ? _c("MuiSelect", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: { isRequired: true },
                              expression: "{ isRequired: true }",
                            },
                          ],
                          staticClass: "w-100",
                          attrs: {
                            options: _vm.reportGroupsData,
                            label: `${_vm.upperFirst(
                              _vm.$t("Common.Descriptions.group")
                            )}*`,
                            placeholder: _vm.$t(
                              "Common.Actions.select_element",
                              { element: _vm.$t("Common.Descriptions.group") }
                            ),
                            "option-label": "group",
                            "option-value": "uuid",
                            name: "group",
                          },
                          model: {
                            value: _vm.inputs.groupUuid,
                            callback: function ($$v) {
                              _vm.$set(_vm.inputs, "groupUuid", $$v)
                            },
                            expression: "inputs.groupUuid",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-md-4 mb-2" },
                  [
                    _vm.ready
                      ? _c("MuiAlgoliaSelect", {
                          staticClass: "w-100",
                          attrs: {
                            index: _vm.ALGOLIA_INDEXES.countries,
                            label: _vm.upperFirst(
                              _vm.$t("Common.Business.country")
                            ),
                            placeholder: _vm.$t(
                              "Common.Actions.select_element",
                              { element: _vm.$t("Common.Business.country") }
                            ),
                            "results-per-page": 1000,
                            title: (result) => result.name,
                            name: "country",
                            "no-cache": "",
                            "path-value": "uuid",
                          },
                          model: {
                            value: _vm.inputs.countryUuid,
                            callback: function ($$v) {
                              _vm.$set(_vm.inputs, "countryUuid", $$v)
                            },
                            expression: "inputs.countryUuid",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-md-4 mb-2" },
                  [
                    _vm.ready
                      ? _c("MuiSelect", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: { isRequired: true },
                              expression: "{ isRequired: true }",
                            },
                          ],
                          staticClass: "w-100",
                          attrs: {
                            options: _vm.values(_vm.REPORT_TYPES),
                            label: `${_vm.$t(
                              "Common.Descriptions.type_element",
                              { element: _vm.$t("Common.Actions.embed") }
                            )}*`,
                            placeholder: _vm.$t(
                              "Common.Actions.select_element",
                              { element: _vm.$t("Common.Descriptions.type") }
                            ),
                            name: "type",
                          },
                          model: {
                            value: _vm.inputs.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.inputs, "type", $$v)
                            },
                            expression: "inputs.type",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-12 pointer-events" },
                  [
                    _vm.inputs.type === _vm.REPORT_TYPES.metabase
                      ? _c("MuiTextarea", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: {
                                isRequired: true,
                                isJsonValid: _vm.isJsonValid,
                              },
                              expression:
                                "{\n              isRequired: true,\n              isJsonValid,\n            }",
                            },
                          ],
                          staticClass: "w-100",
                          attrs: {
                            label: "Payload",
                            placeholder: "Enter a valid JSON",
                            name: "embed",
                            rows: "4",
                          },
                          model: {
                            value: _vm.inputs.embed,
                            callback: function ($$v) {
                              _vm.$set(_vm.inputs, "embed", $$v)
                            },
                            expression: "inputs.embed",
                          },
                        })
                      : _c("MuiInputText", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: { isRequired: true },
                              expression: "{ isRequired: true }",
                            },
                          ],
                          staticClass: "w-100",
                          attrs: {
                            label: `${_vm.$t("Common.Acronyms.URL")}*`,
                            placeholder: "Enter a valid URL",
                            name: "name",
                          },
                          model: {
                            value: _vm.inputs.embed,
                            callback: function ($$v) {
                              _vm.$set(_vm.inputs, "embed", $$v)
                            },
                            expression: "inputs.embed",
                          },
                        }),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          { staticClass: "d-flex align-content-center" },
          [
            _c("CancelButton", { on: { click: _vm.closeModal } }),
            _c(
              "ui-button",
              {
                staticClass: "wmin-initial",
                attrs: {
                  disabled: !_vm.isFormValid,
                  loading: _vm.reportStatus.LOADING,
                },
                on: {
                  clickbutton: function ($event) {
                    _vm.report ? _vm.editReport() : _vm.createReport()
                  },
                },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.report
                        ? _vm.$t("Common.Actions.save")
                        : _vm.$t("Common.Actions.create")
                    ) +
                    " "
                ),
              ]
            ),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }