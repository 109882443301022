var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "SummaryReportView position-relative d-flex flex-column flex-fill px-2 mt-3 px-lg-5",
      attrs: { "data-test-id": "fleet-check-view" },
    },
    [
      _c("h1", { staticClass: "pb-4 d-flex justify-content-between" }, [
        _vm._v(" Summary report "),
      ]),
      _c(
        "div",
        {
          class: [
            "d-flex flex-column flex-fill emobg-background-color-white p-3",
            { fullscreen: _vm.fullscreen },
          ],
          on: {
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              )
                return null
              _vm.fullscreen = false
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "d-flex justify-content-end mb-2" },
            [
              _c("FullscreenButton", {
                attrs: { fullscreen: _vm.fullscreen },
                on: {
                  click: function ($event) {
                    _vm.fullscreen = !_vm.fullscreen
                  },
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "d-flex flex-column flex-fill" }, [
            _c("iframe", {
              staticClass:
                "d-flex flex-column flex-fill emobg-border-1 emobg-border-color-ground-light w-100 h-100",
              attrs: { src: _vm.reportEmbed, title: "embedded report" },
            }),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }