export const REPORT_TYPES = {
  iframe: 'iframe',
  partial: 'partial',
  embed: 'embed',
  modeAnalytics: 'mode-analytics',
  blank: 'blank',
  metabase: 'metabase',
};

export default REPORT_TYPES;
