<script>
import { MuiAlgoliaList } from '@emobg/motion-ui';
import { isNullString, navigationErrorHandler } from '@emobg/web-utils';
import { PageView } from '@/components';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import ReportFormComponent from '../Reports/components/Form/ReportFormComponent';
import {
  attributesToRetrieve,
  contentCells,
  reportsListFacets,
  reportsListFilters,
} from './const/algoliaConfig.const';

export default {
  name: 'ReportsListView',
  components: {
    MuiAlgoliaList,
    PageView,
    ReportFormComponent,
  },
  data() {
    return {
      isModalFormOpen: false,
    };
  },
  created() {
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.attributesToRetrieve = attributesToRetrieve;
    this.reportsListFilters = reportsListFilters;
    this.contentCells = contentCells.bind(this)();
    this.reportsListFacets = reportsListFacets.bind(this)();
  },
  methods: {
    defaultToGlobal(value) {
      return isNullString(value) ? 'Global' : value;
    },
    callback(response) {
      this.$router
        .push({ path: `/business-intelligence/reports/${response.uuid}` })
        .catch(navigationErrorHandler);
    },
  },
};
</script>

<template>
  <PageView class="ReportsListView">
    <div class="d-flex align-items-center mb-2">
      <h1 class="flex-grow-1">
        Reports
      </h1>
      <ui-button
        data-test-id="create_report-button"
        @clickbutton="isModalFormOpen = true"
      >
        Create new report
      </ui-button>
    </div>

    <MuiAlgoliaList
      :facets="reportsListFacets"
      :filters="reportsListFilters"
      :query-parameters="{ attributesToRetrieve }"
      :table-config="contentCells"
      :index="ALGOLIA_INDEXES.reports"
      class="Reports__MuiAlgoliaList"
    />
    <ReportFormComponent
      v-if="isModalFormOpen"
      :title="$t('BusinessIntelligence.Common.reports.addReport')"
      :callback="callback"
      @closeModal="() => isModalFormOpen = false"
    />
  </PageView>
</template>
