import get from 'lodash/get';
import crypto from 'crypto';
import { backOffice } from '@emobg/web-api-client';
import { MODEANALYTICS } from './modeAnalytics.const';

export default async url => {
  // Constants used by encrypted function
  const requestType = 'GET';
  const contentType = null;
  const contentBody = '';
  const contentDigest = crypto.createHash('md5').update(contentBody).digest().toString('base64');
  const timestamp = get(await backOffice.reports.getRealTimestamp(), 'timestamp');
  const embedUrl = `${url}?access_key=${MODEANALYTICS.access}&max_age=86400&timestamp=${timestamp}`;

  // Encrypting
  const requestString = [requestType, contentType, contentDigest, embedUrl, timestamp].join(',');
  const signature = crypto.createHmac('sha256', MODEANALYTICS.secret).update(requestString).digest('hex');

  // URL returned should be similar to
  return `${embedUrl}&signature=${signature}`;
};
