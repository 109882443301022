<script>
import { mapActions, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import {
  CancelButton,
  DeleteModalBodyComponent,
  GenericModalComponent,
  RemoveButton,
} from '@/components';

export default {
  components: {
    DeleteModalBodyComponent,
    GenericModalComponent,
    CancelButton,
    RemoveButton,
  },
  props: {
    report: {
      type: Object,
      required: true,
    },
    callback: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapState(DOMAINS_MODEL.businessIntelligence.reports, {
      isLoading: state => state.STATUS.LOADING,
    }),
  },
  methods: {
    async removeReport() {
      await this.deleteReport(this.report.uuid);
      this.closeModal();
      this.callback();
    },
    closeModal() {
      this.$emit('closeModal');
    },
    ...mapActions(DOMAINS_MODEL.businessIntelligence.reports, [
      'deleteReport',
    ]),
  },
};
</script>
<template>
  <GenericModalComponent
    v-bind="$attrs"
    :size="SIZES.small"
    is-open
  >
    <template #body>
      <DeleteModalBodyComponent
        :text="$t('BusinessIntelligence.Common.report.removeText')"
        title="Are you sure you want to delete this report?"
      />
    </template>
    <template #footer>
      <div class="d-flex align-content-center">
        <CancelButton @click="closeModal" />
        <RemoveButton
          :loading="isLoading"
          @click="removeReport"
        />
      </div>
    </template>
  </GenericModalComponent>
</template>
