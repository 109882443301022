import { RECORD_TYPES } from '@emobg/motion-ui';

export const attributesToRetrieve = [
  'uuid',
  'name',
  'group',
  'country',
];

export const reportsListFilters = 'deleted_at: null';
export function contentCells() {
  return [
    {
      attributeName: 'name',
      title: this.$t('BusinessIntelligence.Common.reports.reportNameLabel'),
      displayPriority: 1,
      type: RECORD_TYPES.link,
      linkClass: 'emobg-link-primary emobg-body-2',
      minWidth: 240,
      url: result => `/business-intelligence/reports/${result.uuid}`,
    },
    {
      attributeName: 'group',
      title: this.$t('BusinessIntelligence.Common.reports.reportGroupLabel'),
      displayPriority: 1,
    },
    {
      attributeName: 'country',
      title: this.$t('BusinessIntelligence.Common.reports.reportCountryLabel'),
      displayPriority: 1,
      transformValue: this.defaultToGlobal,
    },
  ];
}

export function reportsListFacets() {
  return [
    {
      type: 'refinementList',
      props: {
        title: this.$t('BusinessIntelligence.Common.reports.reportCountryLabel'),
        attributeName: 'country',
        showTooltip: true,
        transformValue: this.defaultToGlobal,
      },
    },
    {
      type: 'refinementList',
      props: {
        title: this.$t('BusinessIntelligence.Common.reports.reportGroupLabel'),
        attributeName: 'group',
        showTooltip: true,
      },
    },
  ];
}
