var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "PageView",
    { staticClass: "ReportsListView" },
    [
      _c(
        "div",
        { staticClass: "d-flex align-items-center mb-2" },
        [
          _c("h1", { staticClass: "flex-grow-1" }, [_vm._v(" Reports ")]),
          _c(
            "ui-button",
            {
              attrs: { "data-test-id": "create_report-button" },
              on: {
                clickbutton: function ($event) {
                  _vm.isModalFormOpen = true
                },
              },
            },
            [_vm._v(" Create new report ")]
          ),
        ],
        1
      ),
      _c("MuiAlgoliaList", {
        staticClass: "Reports__MuiAlgoliaList",
        attrs: {
          facets: _vm.reportsListFacets,
          filters: _vm.reportsListFilters,
          "query-parameters": {
            attributesToRetrieve: _vm.attributesToRetrieve,
          },
          "table-config": _vm.contentCells,
          index: _vm.ALGOLIA_INDEXES.reports,
        },
      }),
      _vm.isModalFormOpen
        ? _c("ReportFormComponent", {
            attrs: {
              title: _vm.$t("BusinessIntelligence.Common.reports.addReport"),
              callback: _vm.callback,
            },
            on: { closeModal: () => (_vm.isModalFormOpen = false) },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }