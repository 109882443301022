var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "PageView",
    {
      staticClass:
        "ReportDetailView position-relative d-flex flex-column flex-fill",
    },
    [
      _vm.reportStatus.LOADING
        ? _c("ui-loader", { attrs: { absolute: "", "data-test-id": "loader" } })
        : [
            _c("div", { staticClass: "row mb-3" }, [
              _c("div", { staticClass: "col-12" }, [
                _c("h2", [_vm._v(" " + _vm._s(_vm.report.name) + " ")]),
              ]),
            ]),
            _c(
              "MuiCard",
              {
                staticClass: "d-flex flex-column flex-fill p-3",
                class: {
                  fullscreen: _vm.fullscreen,
                },
                attrs: { nested: true, tabindex: "0" },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "esc", 27, $event.key, [
                        "Esc",
                        "Escape",
                      ])
                    )
                      return null
                    _vm.fullscreen = false
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "d-flex justify-content-end mb-2" },
                  [
                    _c("FullscreenButton", {
                      attrs: { fullscreen: _vm.fullscreen },
                      on: {
                        click: function ($event) {
                          _vm.fullscreen = !_vm.fullscreen
                        },
                      },
                    }),
                    _c("Actions", {
                      staticClass: "mx-2",
                      attrs: {
                        actions: _vm.actions,
                        "list-align": "bottom-center-end",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "d-flex flex-column flex-fill" }, [
                  _vm.report.type === _vm.REPORT_TYPES.embed
                    ? _c("div", {
                        domProps: { innerHTML: _vm._s(_vm.report.embed) },
                      })
                    : _vm.report.type === _vm.REPORT_TYPES.blank
                    ? _c(
                        "a",
                        { attrs: { href: _vm.report.embed, target: "_blank" } },
                        [_vm._v(" " + _vm._s(_vm.report.name) + " ")]
                      )
                    : _c("iframe", {
                        staticClass:
                          "d-flex flex-column flex-fill emobg-border-1 emobg-border-color-ground-light w-100 h-100",
                        attrs: {
                          title: "embedded report",
                          src: _vm.reportEmbed,
                        },
                      }),
                ]),
              ]
            ),
          ],
      _vm.isModalFormOpen && _vm.report
        ? _c("ReportFormComponent", {
            attrs: {
              title: _vm.$t("BusinessIntelligence.Common.report.editReport"),
              report: _vm.report,
              callback: _vm.signUrl,
            },
            on: { closeModal: () => (_vm.isModalFormOpen = false) },
          })
        : _vm._e(),
      _vm.isRemoveModalOpen && _vm.report
        ? _c("RemoveReportComponent", {
            attrs: {
              header: null,
              report: _vm.report,
              callback: _vm.removeCallback,
            },
            on: { closeModal: () => (_vm.isRemoveModalOpen = false) },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }