<script>
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import upperFirst from 'lodash/upperFirst';
import values from 'lodash/values';
import { mapActions, mapState } from 'vuex';
import {
  MuiAlgoliaSelect,
  MuiInputText,
  MuiSelect,
  MuiTextarea,
  MuiValidationWrapper,
  Validate,
} from '@emobg/motion-ui/v1';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { CancelButton, GenericModalComponent } from '@/components';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import REPORT_TYPES from '../../const/REPORT_TYPES';

export default {
  name: 'ReportFormComponent',
  components: {
    CancelButton,
    GenericModalComponent,
    MuiAlgoliaSelect,
    MuiInputText,
    MuiSelect,
    MuiTextarea,
    MuiValidationWrapper,
  },
  directives: {
    Validate,
  },
  props: {
    report: {
      type: Object,
      default: null,
    },
    callback: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isFormValid: false,
      ready: false,
      uuid: null,
      inputs: {
        name: null,
        groupUuid: null,
        countryUuid: null,
        type: null,
        embed: null,
      },
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.businessIntelligence.reports, {
      reportStatus: state => state.STATUS,
      reportData: state => state.data,
      reportErrors: state => state.error,
    }),
    ...mapState(DOMAINS_MODEL.businessIntelligence.reportGroups, {
      reportGroupsData: state => state.data,
    }),
  },
  async created() {
    this.REPORT_TYPES = REPORT_TYPES;
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    await this.getReportGroups();

    if (this.report) {
      this.uuid = this.report.uuid;
      this.inputs.name = this.report.name;
      this.inputs.groupUuid = this.report.groupUuid;
      this.inputs.countryUuid = this.report.countryUuid;
      this.inputs.type = this.report.type;
      this.inputs.embed = this.report.embed;
    }

    this.ready = true;
  },
  methods: {
    upperFirst,
    values,
    closeModal() {
      this.$emit('closeModal');
    },
    async createReport() {
      await this.postReport(this.inputs);
      this.afterRequest();
    },
    async editReport() {
      await this.patchReport({
        uuid: this.uuid,
        report: this.inputs,
      });

      this.afterRequest();
    },
    async afterRequest() {
      if (this.reportStatus.LOADED) {
        this.closeModal();
        await this.callback(this.reportData);
      } else if (this.reportStatus.ERROR) {
        this.$throwError(this.reportErrors);
      }
    },
    ...mapActions(DOMAINS_MODEL.businessIntelligence.reportGroups, [
      'getReportGroups',
    ]),
    ...mapActions(DOMAINS_MODEL.businessIntelligence.reports, [
      'postReport',
      'patchReport',
    ]),
    isJsonValid() {
      let message = '';
      let isValid = true;
      if (this.inputs.type === REPORT_TYPES.metabase) {
        try {
          const payload = JSON.parse(this.inputs.embed);
          if (!has(payload, 'resource')) {
            message = 'You need specify property "resource" at first level';
            isValid = false;
          } else if (isEmpty(payload.resource)) {
            message = 'You need add some resource key (question/dashboard)';
            isValid = false;
          } else if (!has(payload, 'params')) {
            message = 'You need specify property "params" at first level';
            isValid = false;
          }
        } catch (error) {
          message = error.message;
          isValid = false;
        }
      }
      return {
        isValid,
        message,
      };
    },
  },
};
</script>

<template>
  <GenericModalComponent v-bind="$attrs">
    <template slot="body">
      <MuiValidationWrapper @areAllValid="valid => isFormValid = valid">
        <div class="row">
          <div class="col-12 mb-2">
            <MuiInputText
              v-model="inputs.name"
              v-validate="{ isRequired: true }"
              :label="`${upperFirst($t('Common.Descriptions.name'))}*`"
              placeholder="Enter a name"
              class="w-100"
              name="name"
            />
          </div>
          <div class="col-md-4 mb-2">
            <MuiSelect
              v-if="ready"
              v-model="inputs.groupUuid"
              v-validate="{ isRequired: true }"
              :options="reportGroupsData"
              :label="`${upperFirst($t('Common.Descriptions.group'))}*`"
              :placeholder="$t('Common.Actions.select_element', {element: $t('Common.Descriptions.group')})"
              option-label="group"
              option-value="uuid"
              name="group"
              class="w-100"
            />
          </div>
          <div class="col-md-4 mb-2">
            <MuiAlgoliaSelect
              v-if="ready"
              v-model="inputs.countryUuid"
              :index="ALGOLIA_INDEXES.countries"
              :label="upperFirst($t('Common.Business.country'))"
              :placeholder="$t('Common.Actions.select_element', {element: $t('Common.Business.country')})"
              :results-per-page="1000"
              :title="result => result.name"
              name="country"
              no-cache
              path-value="uuid"
              class="w-100"
            />
          </div>
          <div class="col-md-4 mb-2">
            <MuiSelect
              v-if="ready"
              v-model="inputs.type"
              v-validate="{ isRequired: true }"
              :options="values(REPORT_TYPES)"
              :label="`${ $t('Common.Descriptions.type_element', { element: $t('Common.Actions.embed') })}*`"
              :placeholder="$t('Common.Actions.select_element', {element: $t('Common.Descriptions.type')})"
              name="type"
              class="w-100"
            />
          </div>
          <div class="col-12 pointer-events">
            <MuiTextarea
              v-if="inputs.type === REPORT_TYPES.metabase"
              v-model="inputs.embed"
              v-validate="{
                isRequired: true,
                isJsonValid,
              }"
              label="Payload"
              placeholder="Enter a valid JSON"
              class="w-100"
              name="embed"
              rows="4"
            />
            <MuiInputText
              v-else
              v-model="inputs.embed"
              v-validate="{ isRequired: true }"
              :label="`${$t('Common.Acronyms.URL')}*`"
              placeholder="Enter a valid URL"
              name="name"
              class="w-100"
            />
          </div>
        </div>
      </MuiValidationWrapper>
    </template>
    <template slot="footer">
      <div class="d-flex align-content-center">
        <CancelButton @click="closeModal" />
        <ui-button
          :disabled="!isFormValid"
          :loading="reportStatus.LOADING"
          class="wmin-initial"
          @clickbutton="report ? editReport() : createReport()"
        >
          {{ report ?
            $t('Common.Actions.save') :
            $t('Common.Actions.create')
          }}
        </ui-button>
      </div>
    </template>
  </GenericModalComponent>
</template>
