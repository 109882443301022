var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._b(
      {
        attrs: { size: _vm.SIZES.small, "is-open": "" },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c("DeleteModalBodyComponent", {
                  attrs: {
                    text: _vm.$t(
                      "BusinessIntelligence.Common.report.removeText"
                    ),
                    title: "Are you sure you want to delete this report?",
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "d-flex align-content-center" },
                  [
                    _c("CancelButton", { on: { click: _vm.closeModal } }),
                    _c("RemoveButton", {
                      attrs: { loading: _vm.isLoading },
                      on: { click: _vm.removeReport },
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      },
      "GenericModalComponent",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }