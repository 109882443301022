<script>
import get from 'lodash/get';
import { mapActions, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { MuiCard } from '@emobg/vue-base';
import { navigationErrorHandler } from '@emobg/web-utils';
import { Actions, FullscreenButton, PageView } from '@/components';
import ReportFormComponent from '../Reports/components/Form/ReportFormComponent';
import RemoveReportComponent from '../Reports/components/Remove/RemoveReportComponent';
import REPORT_TYPES from '../Reports/const/REPORT_TYPES';
import SignModeAnalytics from '../utils/SignModeAnalytics';
import SignMetaBase from '../utils/SignMetaBase';

export default {
  components: {
    MuiCard,
    Actions,
    FullscreenButton,
    PageView,
    ReportFormComponent,
    RemoveReportComponent,
  },
  data() {
    return {
      reportUuid: get(this, '$route.params.reportUuid'),
      reportEmbed: '',
      fullscreen: false,
      isModalFormOpen: false,
      isRemoveModalOpen: false,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.businessIntelligence.reports, {
      reportStatus: state => state.STATUS,
      report: state => state.data,
      reportErrors: state => state.error,
    }),
    ...mapState(DOMAINS_MODEL.app.setup, {
      metabaseConfig: state => get(state, 'ENV.metabase'),
    }),
  },
  async created() {
    this.REPORT_TYPES = REPORT_TYPES;

    this.actions = [
      {
        label: this.$t('BusinessIntelligence.Common.report.editReport'),
        action: () => { this.isModalFormOpen = true; },
      },
      {
        label: this.$t('BusinessIntelligence.Common.report.deleteReport'),
        action: () => { this.isRemoveModalOpen = true; },
      },
    ];

    await this.getReportInfo();

    await this.signUrl();
  },
  methods: {
    async signUrl() {
      const embed = get(this.report, 'embed');
      switch (this.report.type) {
        case REPORT_TYPES.modeAnalytics:
          this.reportEmbed = await SignModeAnalytics(embed);
          break;
        case REPORT_TYPES.metabase:
          this.reportEmbed = SignMetaBase(embed, this.metabaseConfig);
          break;
        default:
          this.reportEmbed = embed;
      }
    },
    async getReportInfo() {
      await this.getReport(this.reportUuid);
      if (this.reportStatus.ERROR) {
        this.$throwError(this.reportErrors);
      }
    },
    removeCallback() {
      this.$router.push({ path: '/business-intelligence/reports' }).catch(navigationErrorHandler);
    },
    ...mapActions(DOMAINS_MODEL.businessIntelligence.reports, [
      'getReport',
    ]),
  },
};
</script>

<template>
  <PageView class="ReportDetailView position-relative d-flex flex-column flex-fill">
    <ui-loader
      v-if="reportStatus.LOADING"
      absolute
      data-test-id="loader"
    />

    <template v-else>
      <div class="row mb-3">
        <div class="col-12">
          <h2>
            {{ report.name }}
          </h2>
        </div>
      </div>

      <MuiCard
        :nested="true"
        class="d-flex flex-column flex-fill p-3"
        tabindex="0"
        :class="{
          'fullscreen': fullscreen
        }"
        @keyup.native.esc="fullscreen = false"
      >
        <div class="d-flex justify-content-end mb-2">
          <FullscreenButton
            :fullscreen="fullscreen"
            @click="fullscreen = !fullscreen"
          />
          <Actions
            :actions="actions"
            class="mx-2"
            list-align="bottom-center-end"
          />
        </div>

        <div class="d-flex flex-column flex-fill">
          <!-- Embed type -->
          <!-- eslint-disable vue/no-v-html -->
          <div
            v-if="report.type === REPORT_TYPES.embed"
            v-html="report.embed"
          />
          <!-- eslint-enable vue/no-v-html -->
          <!-- Blank type -->
          <a
            v-else-if="report.type === REPORT_TYPES.blank"
            :href="report.embed"
            target="_blank"
          >
            {{ report.name }}
          </a>
          <!-- Other type -->
          <iframe
            v-else
            title="embedded report"
            :src="reportEmbed"
            class="d-flex flex-column flex-fill emobg-border-1 emobg-border-color-ground-light w-100 h-100"
          />
        </div>
      </MuiCard>
    </template>
    <ReportFormComponent
      v-if="isModalFormOpen && report"
      :title="$t('BusinessIntelligence.Common.report.editReport')"
      :report="report"
      :callback="signUrl"
      @closeModal="() => isModalFormOpen = false"
    />
    <RemoveReportComponent
      v-if="isRemoveModalOpen && report"
      :header="null"
      :report="report"
      :callback="removeCallback"
      @closeModal="() => isRemoveModalOpen = false"
    />
  </PageView>
</template>
