<script>
import get from 'lodash/get';
import { mapState } from 'vuex';

import { signJWT } from '@emobg/web-utils';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';

import { FullscreenButton } from '@/components';

export default {
  name: 'SummaryReportView',
  components: {
    FullscreenButton,
  },
  data() {
    return {
      reportPayload: {
        resource: { dashboard: 210 },
        params: {},
        exp: Math.round(Date.now() / 1000) + (10 * 60), // 10 minute expiration
      },
      reportEmbed: '',
      fullscreen: false,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.setup, {
      metabaseUrl: state => get(state, 'ENV.metabase.url'),
      metabaseSecretKey: state => get(state, 'ENV.metabase.secretKey'),
    }),
  },
  mounted() {
    const token = signJWT(this.reportPayload, this.metabaseSecretKey);
    this.reportEmbed = `${this.metabaseUrl}/embed/dashboard/${token}#bordered=true&titled=true`;
  },
};
</script>

<template>
  <div
    class="SummaryReportView position-relative d-flex flex-column flex-fill px-2 mt-3 px-lg-5"
    data-test-id="fleet-check-view"
  >
    <h1 class="pb-4 d-flex justify-content-between">
      Summary report
    </h1>

    <div
      :class="[
        'd-flex flex-column flex-fill emobg-background-color-white p-3',
        { 'fullscreen': fullscreen },
      ]"
      @keyup.esc="(fullscreen = false)"
    >
      <div class="d-flex justify-content-end mb-2">
        <FullscreenButton
          :fullscreen="fullscreen"
          @click="fullscreen = !fullscreen"
        />
      </div>

      <div class="d-flex flex-column flex-fill">
        <iframe
          :src="reportEmbed"
          title="embedded report"
          class="d-flex flex-column flex-fill emobg-border-1 emobg-border-color-ground-light w-100 h-100"
        />
      </div>
    </div>
  </div>
</template>
