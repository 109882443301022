import { render, staticRenderFns } from "./RemoveReportComponent.vue?vue&type=template&id=6ebda30c&"
import script from "./RemoveReportComponent.vue?vue&type=script&lang=js&"
export * from "./RemoveReportComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6ebda30c')) {
      api.createRecord('6ebda30c', component.options)
    } else {
      api.reload('6ebda30c', component.options)
    }
    module.hot.accept("./RemoveReportComponent.vue?vue&type=template&id=6ebda30c&", function () {
      api.rerender('6ebda30c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/domains/BusinessIntelligence/Reports/components/Remove/RemoveReportComponent.vue"
export default component.exports